import React, { useState, useEffect } from 'react';
import { FaInstagram, FaTwitter, FaArrowDown, FaLinkedin, FaGithub, FaChevronLeft, FaChevronRight, FaPhp, FaSwift, FaAndroid, FaRust, FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { FaReact, FaNodeJs, FaPython, FaAws, FaDocker, FaDatabase, FaJs, FaVuejs, FaAngular, FaJava } from 'react-icons/fa';
import { SiGraphql, SiTensorflow, SiKubernetes, SiTypescript, SiExpress, SiDjango, SiRubyonrails, SiSpring, SiMicrosoftazure, SiGooglecloud, SiKotlin, SiFlutter, SiMysql, SiMeilisearch, SiElasticsearch } from 'react-icons/si';
import { Helmet } from 'react-helmet';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from './logo_branca.png';
import logoRoxo from './logo_roxo.png';
import icone from './icone_roxo.png';
import backgroundImage from './background_image2.jpg';
import lincoln from './lincoln.jpeg';
import helio from './helio.jpeg';
import { useSwipeable } from 'react-swipeable';
import { DiRedis } from 'react-icons/di';

const App = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const technologies = [
    { name: 'Azure', icon: SiMicrosoftazure },
    { name: 'React', icon: FaReact },
    { name: 'Node.js', icon: FaNodeJs },
    { name: 'Python', icon: FaPython },
    { name: 'AWS', icon: FaAws },
    { name: 'Docker', icon: FaDocker },
    { name: 'MongoDB', icon: FaDatabase },
    { name: 'PostgreSQL', icon: FaDatabase },
    { name: 'GraphQL', icon: SiGraphql },
    { name: 'GCP', icon: SiGooglecloud },
    { name: 'Kubernetes', icon: SiKubernetes },
    { name: 'TypeScript', icon: SiTypescript },
    { name: 'Vue.js', icon: FaVuejs },
    { name: 'Express.js', icon: SiExpress },
    { name: 'PHP', icon: FaPhp },
    { name: 'Java', icon: FaJava },
    { name: 'Swift', icon: FaSwift },
    { name: 'Kotlin ', icon: SiKotlin },
    { name: 'Android ', icon: FaAndroid },
    { name: 'Flutter ', icon: SiFlutter },
    { name: 'Mysql ', icon: SiMysql },
    { name: 'Rust ', icon: FaRust },
    { name: 'Meilisearch  ', icon: SiMeilisearch },
    { name: 'Elasticsearch', icon: SiElasticsearch },
    { name: 'Redis ', icon: DiRedis },
  ];

  const itemsPerSlide = 5;
  const totalSlides = Math.ceil(technologies.length / itemsPerSlide);
  const [activeSection, setActiveSection] = useState('');

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['inicio', 'servicos', 'processo', 'equipe', 'contact'];
      let currentSection = '';

      sections.forEach((sectionId) => {
        const section = document.getElementById(sectionId);
        if (section && window.scrollY >= section.offsetTop - 60) {
          currentSection = sectionId;
        }
      });

      setActiveSection(currentSection);

      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % totalSlides);
    }, 5000);
    return () => clearInterval(interval);
  }, [totalSlides]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="font-sans">
      <Helmet>
        <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
      </Helmet>

      {/* Header Section */}
      <header className={`fixed w-full z-10 h-16 p-4 transition-colors duration-300 ${isScrolled ? 'bg-white text-[#6B7D8C]' : 'bg-transparent text-white'}`}>
        <div className="container mx-auto flex justify-between items-center">
          {/* Logo */}
          <div className="relative" style={{ width: '150px', height: 'auto' }}>
            <img
              src={isScrolled ? icone : logo}
              alt="Logo da Empresa"
              className={isScrolled ? "absolute top-[-45px] h-20 object-contain" : "absolute top-[-70px] h-70 object-contain"}
            />
          </div>

          {/* Menu Hamburguer para dispositivos móveis */}
          <div className="md:hidden">
            <button
              onClick={toggleMobileMenu}
              aria-label="Abrir menu"
              className={`${isScrolled ? 'text-[#6B7D8C]' : 'text-[#783BFF]'} text-3xl`}>
              {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>

          {/* Menu completo para telas maiores */}
          <nav className="hidden md:block">
            <ul className="flex space-x-4 items-center text-lg font-semibold">
              <li>
                <button
                  onClick={() => scrollToSection('inicio')}
                  className={`font-montserrat ${activeSection === 'inicio' ? 'text-[#783BFF]' : isScrolled ? 'text-[#6B7D8C] hover:text-[#783BFF]' : 'text-white hover:text-[#783BFF]'} transition-colors duration-300`}
                >
                  Início
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('servicos')}
                  className={`font-montserrat ${activeSection === 'servicos' ? 'text-[#783BFF]' : isScrolled ? 'text-[#6B7D8C] hover:text-[#783BFF]' : 'text-white hover:text-[#783BFF]'} transition-colors duration-300`}
                >
                  Serviços
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('processo')}
                  className={`font-montserrat ${activeSection === 'processo' ? 'text-[#783BFF]' : isScrolled ? 'text-[#6B7D8C] hover:text-[#783BFF]' : 'text-white hover:text-[#783BFF]'} transition-colors duration-300`}
                >
                  Processo
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('equipe')}
                  className={`font-montserrat ${activeSection === 'equipe' ? 'text-[#783BFF]' : isScrolled ? 'text-[#6B7D8C] hover:text-[#783BFF]' : 'text-white hover:text-[#783BFF]'} transition-colors duration-300`}
                >
                  Equipe
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('contact')}
                  className={`font-montserrat border ${activeSection === 'contact' ? 'text-[#783BFF] border-[#783BFF]' : isScrolled ? 'text-[#6B7D8C] border-[#6B7D8C] hover:bg-[#783BFF] hover:text-white' : 'text-white border-white hover:bg-[#783BFF]'} px-4 py-2 rounded-full transition-all duration-300`}
                >
                  Contato
                </button>
              </li>
            </ul>
          </nav>


          {/* Menu Mobile */}
          {isMobileMenuOpen && (
            <nav className="fixed top-16 left-0 w-full bg-[#151342] z-20 md:hidden">
              <ul className="flex flex-col space-y-4 p-4">
                <li><button onClick={() => { scrollToSection('inicio'); toggleMobileMenu(); }} className="hover:text-[#783BFF] font-montserrat">Início</button></li>
                <li><button onClick={() => { scrollToSection('servicos'); toggleMobileMenu(); }} className="hover:text-[#783BFF] font-montserrat">Serviços</button></li>
                <li><button onClick={() => { scrollToSection('processo'); toggleMobileMenu(); }} className="hover:text-[#783BFF] font-montserrat">Processo</button></li>
                <li><button onClick={() => { scrollToSection('equipe'); toggleMobileMenu(); }} className="hover:text-[#783BFF] font-montserrat">Equipe</button></li>
                <li><button onClick={() => { scrollToSection('contact'); toggleMobileMenu(); }} className="hover:text-[#783BFF] font-montserrat">Contato</button></li>
              </ul>
            </nav>
          )}
        </div>
      </header>


      {/* Main Text Section */}
      <section
        id="inicio"
        className="bg-gradient-to-r from-[#151342] to-[#783BFF] text-white min-h-screen flex items-center pt-16"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="container mx-auto text-center px-4">
          <motion.h1
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="text-5xl md:text-6xl font-bold mb-6 font-zendots"
          >
            Desenvolva seu projeto com a Expertise de Gigantes
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
            className="text-xl md:text-2xl mb-8 font-montserrat"
          >
            Imagine seu projeto sendo executado pelos mesmos desenvolvedores por trás das principais plataformas de e-commerce, bancos, fintechs e esports da América Latina. Aqui, combinamos experiência com soluções acessíveis.
          </motion.p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => scrollToSection('contact')}
            className="bg-white text-[#783BFF] px-8 py-3 rounded-full text-lg font-semibold hover:bg-[#F4F4F4] transition duration-300 font-montserrat"
          >
            Entre em contato <FaArrowDown className="inline ml-2" />
          </motion.button>
        </div>
      </section>

      {/* Company Characteristics Section */}
      <section id="servicos" className="py-20 bg-[#F4F4F4]">
        <div className="container mx-auto px-4">
          <div className="flex flex-col space-y-8 md:flex-row items-start">
            <div className="w-full md:w-1/2">
              <h2 className="text-3xl font-bold text-[#151342] font-zendots mb-6">Nossa Especialidade</h2>
              <div className="space-y-6">
                <div className="flex items-start">
                  <div className="text-[#783BFF] mr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-[#151342]">Desenvolvimento de Software Sob Medida</h3>
                    <p className="text-[#6B7D8C]">Tem uma ideia incrível e precisa transformá-la em um software funcional? Estamos aqui para idealizar, planejar e desenvolver um software sob medida que execute exatamente o que você imagina.</p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="text-[#783BFF] mr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-[#151342]">Execução de Projetos de Software</h3>
                    <p className="text-[#6B7D8C]">Já tem um projeto bem definido, mas precisa de uma equipe para desenvolvê-lo? Oferecemos a execução completa do seu projeto, garantindo que sua visão se torne realidade com eficiência e qualidade.</p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="text-[#783BFF] mr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-[#151342]">Otimização e Reformulação de Software</h3>
                    <p className="text-[#6B7D8C]">Enfrentando problemas com um projeto em andamento ou precisa melhorar funcionalidades existentes? Nossa equipe pode avaliar sua situação atual e implementar melhorias, reformando ou ajustando o software para otimizá-lo.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/2">
              <img
                src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                alt="Colaboração da equipe"
                className="rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>
      </section>


      {/* New Section: Development Process */}
      <section id="processo" className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-[#151342] font-zendots">Nosso Processo de Desenvolvimento</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                title: 'Primeiro bate-papo',
                description: 'Entendemos suas necessidades e objetivos em uma reunião detalhada e sem compromisso, no seu estilo.',
                icon: '🤝'
              },
              {
                title: 'Planejamento',
                description: 'Elaboramos um plano detalhado e timeline para o seu projeto, em seguida, enviamos para sua aprovação.',
                icon: '📅'
              },
              {
                title: 'Desenvolvimento',
                description: 'Após sua aprovação, começamos a desenvolver o projeto com base nas tecnologias mais recentes e melhores práticas.',
                icon: '💻'
              },
              {
                title: 'Entrega e Suporte',
                description: 'Entregamos o projeto finalizado e oferecemos suporte contínuo para garantir que tudo funcione perfeitamente.',
                icon: '🚀'
              }
            ].map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="bg-[#F4F4F4] rounded-lg p-6 shadow-lg"
              >
                <div className="text-4xl mb-4">{step.icon}</div>
                <h3 className="text-xl font-semibold mb-2 text-[#151342] font-zendots">{step.title}</h3>
                <p className="text-[#151342] font-montserrat">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Team Members Section */}
      <section id="equipe" className="py-20 bg-[#F4F4F4]">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-[#151342] font-zendots">Fundadores</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 justify-center">
            {[
              {
                name: 'Lincoln Matos',
                role: 'CEO & fundador',
                bio: [
                  '+12 anos de experiência no mercado de TI com fintechs, ecommerces, marketplaces e-sports e turistech.',
                  'Trabalhou em projetos de gigantes como Nike, Multilaser, Hostgator, Gamers Club, BTG Pactual e Coral Tintas.',
                  'Fundador e líder de projetos com foco em inovação tecnológica e escalabilidade.'
                ],
                image: lincoln,
                linkedin: 'https://www.linkedin.com/in/lincoln-biancardi-9429622a/',
                github: 'https://github.com/Lincolnbiancard'
              },
              {
                name: 'Helio Biancardi',
                role: 'CTO & fundador',
                bio: [
                  '+10 anos de experiência no mercado de TI com arquitetura de software e liderança técnica.',
                  'Trabalhou em projetos de gigantes como: Grupo Casas Bahia, Banqi, Unilever, Mondelez, Faber Castell, Hersheys e Visa.',
                  'Especialista em soluções escaláveis e automação, com forte atuação em desenvolvimento ágil e inovação tecnológica.'
                ],
                image: helio,
                linkedin: 'https://www.linkedin.com/in/helio-biancardi/',
                github: 'https://github.com/Lincolnbiancard'
              }
            ].map((member, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="bg-white rounded-lg overflow-hidden shadow-lg"
              >
                <div className="h-96 overflow-hidden"> {/* Aumentando a altura da imagem */}
                  <img src={member.image} alt={member.name} className="w-full h-full object-top object-contain" />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2 text-[#151342] font-zendots">{member.name}</h3>
                  <p className="text-[#783BFF] mb-4 font-montserrat">{member.role}</p>
                  {member.bio.map((paragraph, i) => (
                    <p key={i} className="text-[#151342] font-montserrat mb-4">{paragraph}</p>
                  ))}
                  <div className="mt-4 flex space-x-4">
                    <a href={member.linkedin} className="text-[#151342] hover:text-[#783BFF]" target="_blank" rel="noopener noreferrer">
                      <FaLinkedin size={24} />
                    </a>
                    <a href={member.github} className="text-[#151342] hover:text-[#783BFF]" target="_blank" rel="noopener noreferrer">
                      <FaGithub size={24} />
                    </a>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>



      {/* Technology Carousel Section */}
      <section className="py-20 bg-[#151342] text-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 font-zendots">Algumas das tecnologias que usamos</h2>

          <div className="relative overflow-hidden h-20">
            {/* Botão esquerdo */}
            <button
              onClick={prevSlide}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-[#783BFF] p-2 rounded-full z-10 hidden md:block"
            >
              <FaChevronLeft className="text-white" />
            </button>

            {/* Conteúdo deslizante com swipe */}
            <motion.div
              {...useSwipeable({
                onSwipedLeft: () => nextSlide(),
                onSwipedRight: () => prevSlide(),
              })}
              animate={{ x: `-${currentSlide * 100}%` }}
              transition={{ ease: "linear", duration: 0.5 }}
              className="flex absolute w-full"
            >
              {Array(totalSlides).fill().map((_, slideIndex) => (
                <div key={slideIndex} className="flex-shrink-0 w-full flex justify-around items-center">
                  {technologies.slice(slideIndex * itemsPerSlide, (slideIndex + 1) * itemsPerSlide).map((tech, index) => {
                    const Icon = tech.icon;
                    return (
                      <div key={index} className="flex flex-col items-center">
                        <Icon className="text-4xl mb-2 text-[#783BFF]" />
                        <span className="text-sm font-montserrat mt-2">{tech.name}</span> {/* Nome abaixo do ícone */}
                      </div>
                    );
                  })}
                </div>
              ))}
            </motion.div>

            {/* Botão direito */}
            <button
              onClick={nextSlide}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-[#783BFF] p-2 rounded-full z-10 hidden md:block"
            >
              <FaChevronRight className="text-white" />
            </button>
          </div>
        </div>
      </section>

      {/* Contact Form Section with Calendly Integration */}
      <section id="contact" className="py-20 bg-[#783BFF] text-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 font-zendots">Agende uma Reunião sem compromisso</h2>

          <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-xl overflow-hidden">
            <div
              className="calendly-inline-widget"
              data-url="https://calendly.com/biancardilincoln/30min?hide_event_type_details=1&primary_color=783bff"
              style={{ minWidth: '320px', height: '700px' }}
            ></div>
          </div>

          {/* Seção de WhatsApp e Email com ícones */}
          <div className="mt-8 flex flex-col md:flex-row justify-center items-center md:space-x-12 text-center">
            <div className="flex items-center space-x-2">
              <FaWhatsapp className="text-2xl" /> {/* Ícone de WhatsApp */}
              <a
                href="https://wa.me/5511932689884"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white font-semibold hover:underline flex flex-col items-center"
              >
                <span className="font-bold text-lg">WhatsApp</span>
                <span className="text-md mt-1">+55 (11)93268-9884</span>
              </a>
            </div>
            <div className="mt-4 md:mt-0 flex items-center space-x-2">
              <FaEnvelope className="text-2xl" /> {/* Ícone de Email */}
              <a
                href="mailto:suporte@biancode.com.br"
                className="text-white font-semibold hover:underline flex flex-col items-center"
              >
                <span className="font-bold text-lg">Email</span>
                <span className="text-md mt-1">suporte@biancode.com.br</span>
              </a>
            </div>
          </div>

          <p className="text-center mt-8 font-montserrat">
            Use o calendário acima para escolher um horário conveniente para nossa reunião.
            Após a confirmação, você receberá um convite por e-mail com os detalhes da reunião.
          </p>
        </div>
      </section>




      { /* Footer */}
      <footer className="bg-white py-10">
        <div className="container mx-auto px-4">
          {/* Primeira linha com logo e frase */}
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center space-x-8">
              <img src={logoRoxo} alt="Logo" className="h-32 md:h-48 w-auto" />
              <div className="text-black text-lg">
                <p className="font-bold">Transformando ideias em realidade.</p>
                <p>sonhe grande, construa maior_</p>
              </div>
            </div>

            {/* Links */}
            <div className="flex space-x-8 mt-6 md:mt-0">
              <a href="#" className="text-[#783BFF] hover:underline font-bold">carreira</a>
              <a href="#" className="text-[#783BFF] hover:underline font-bold">insights</a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="text-[#783BFF] hover:text-[#5a30cc]">
                <FaInstagram className="h-8 w-8" />
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="text-[#783BFF] hover:text-[#5a30cc]">
                <FaTwitter className="h-8 w-8" />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="text-[#783BFF] hover:text-[#5a30cc]">
                <FaLinkedin className="h-8 w-8" />
              </a>
            </div>
          </div>

          {/* Endereço e informações */}
          <div className="mt-8 flex flex-col md:flex-row justify-between items-start text-gray-700">
            <div className="text-center md:text-left">
              <h4 className="font-bold">BianCode SP</h4>
              <p>Jundiaí, São Paulo</p>
            </div>
            <div className="text-center md:text-left mt-6 md:mt-0">
              <h4 className="font-bold">BianCode ES</h4>
              <p>Guarapari, ES</p>
            </div>
            <div className="text-center md:text-left mt-6 md:mt-0">
              <h4 className="font-bold">BianCode Internacional</h4>
              <p>Em breve.</p>
            </div>
          </div>

          {/* Redes Sociais */}
          <div className="flex justify-center space-x-4 mt-8">
            <a href="#" className="text-[#ff5c00]"><i className="fab fa-linkedin fa-lg"></i></a>
            <a href="#" className="text-[#ff5c00]"><i className="fab fa-instagram fa-lg"></i></a>
            <a href="#" className="text-[#ff5c00]"><i className="fab fa-facebook fa-lg"></i></a>
            <a href="#" className="text-[#ff5c00]"><i className="fab fa-youtube fa-lg"></i></a>
          </div>

          {/* Políticas */}
          <div className="text-center text-gray-500 mt-6">
            <a href="#" className="hover:underline">Política Global de Privacidade</a> |
            <a href="#" className="hover:underline ml-2">Política do Código de Conduta</a>
          </div>

          {/* Direitos reservados */}
          <p className="text-center text-gray-400 mt-4">© BianCode, 2024. Todos os Direitos Reservados</p>
        </div>
      </footer>
    </div>

  );
};

export default App;
